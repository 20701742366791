<template>
  <div>
    <div style="white-space: pre-wrap; word-wrap: break-word">
      <!-- 正解の表示 -->
      <span v-if="show.correctBox">
        <br />
        <v-btn
          text
          large
          @click="show.correctContent = !show.correctContent"
          color="orange darken-1"
        >
          <h2>
            <v-icon v-if="!show.correctContent" left
              >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="show.correctContent" left
              >mdi-arrow-down-drop-circle</v-icon
            >
            正解
          </h2>
        </v-btn>
        <span v-html="content.correct" v-if="show.correctContent"></span>
      </span>
      <!-- 解説の表示 -->
      <span v-if="show.commentBox">
        <br />
        <v-btn
          text
          large
          @click="show.commentContent = !show.commentContent"
          color="orange darken-1"
        >
          <h2>
            <v-icon v-if="!show.commentContent" left
              >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="show.commentContent" left
              >mdi-arrow-down-drop-circle</v-icon
            >
            解説
          </h2>
        </v-btn>
        <div v-html="content.comment" v-if="show.commentContent"></div>
      </span>
      <!-- 参考の表示 新データではカット-->
      <span v-if="common.showRefBlock">
        <br />
        <v-btn
          text
          large
          @click="common.showContentRef = !common.showContentRef"
          color="orange darken-1"
        >
          <h2>
            <v-icon v-if="!common.showContentRef" left
              >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="common.showContentRef" left
              >mdi-arrow-down-drop-circle</v-icon
            >
            参考
          </h2>
        </v-btn>
        <div v-html="common.contentRef" v-if="common.showContentRef"></div>
      </span>
      <!-- 外部参考の表示 -->
      <span v-if="exRef.showExRefBlock">
        <br />
        <v-btn
          text
          large
          @click="exRef.showContentExRef = !exRef.showContentExRef"
          color="orange darken-1"
        >
          <h2>
            <v-icon v-if="!exRef.showContentExRef" left
              >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="exRef.showContentExRef" left
              >mdi-arrow-down-drop-circle</v-icon
            >
            参考（外部リンク）
          </h2>
        </v-btn>
        <div v-if="exRef.showContentExRef">
          <p v-for="ex in arrExRef" :key="ex.ind">
            <font size="3">
              <a :href="ex.url" target="_blank">{{ ex.title }}</a>
            </font>
          </p>
        </div>
      </span>
    </div>
  </div>
</template>

<style></style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AnswerAndComment',
  data() {
    return {
      selKamokuName: '',
      show: {
        correctBox: false, //正解ブロックの表示
        correctContent: false, //正解内容の表示（アコーディオン的に、表示中か非表示中かを区別）
        commentBox: false, //解説ブロックの表示
        commentContent: false, //解説内容の表示（アコーディオン的に、表示中か非表示中かを区別）
      },
      content: {
        correct: '', //正解内容
        comment: '',
      },
      common: {
        showRefBlock: false, //参照ブロックの表示
        showContentRef: false, //参照内容の表示（アコーディオン的に、表示中か非表示中かを区別）
        contentRef: '', //参照内容
      },
      exRef: {
        showExRefBlock: false,
        showContentExRef: false,
      },
      arrExRef: [],

      initData: ['show', 'content', 'common', 'exRef'], //プログラマブルに初期化するためのデータ
    };
  },
  computed: {
    ...mapState(['cont', 'saveData']),
  },
  methods: {
    setContents(obj) {
      this.init(); //初期化
      this.selKamokuName = obj.selKamokuName;
      this.setAnswer(obj.question);
      //this.setReference(ele, selKamokuName, publicData); //共通項目である参考の表示
      this.setExRef(obj.question);
    },
    init() {
      this.initData.forEach((key) => {
        const target = this[key];
        Object.keys(target).forEach((key) => {
          if (typeof target[key] == 'boolean') {
            target[key] = false;
          }
          if (typeof target[key] == 'string') {
            target[key] = '';
          }
          if (typeof target[key] == 'object') {
            if (Array.isArray(target[key])) {
              target[key] = [];
            } else {
              target[key] = {};
            }
          }
          if (typeof target[key] == 'number') {
            target[key] = 0;
          }
        });
      });
    },
    //======================================================================================
    async setAnswer(ele) {
      const queFormat = ele['queFormat'];
      if (queFormat == 'perfectMatch') {
        //完全一致型の解答を表示
        var content = '<h3>正しい順番</h3>';

        const arr = this.gf_isNewType(this.selKamokuName)
          ? ele.obj_answer
          : this.getArrayFromCellData(ele.answer);
        for (const i in arr) {
          const num = Number(i) + 1;
          if (arr[i] == '') {
            continue;
          }
          if (i == 0) {
            content += '【' + num + '】' + arr[i];
          } else if (i == arr.length - 1) {
            content += '<br>【' + num + '】' + arr[i] + '<br>'; //この<br>要素がないと要素が詰まってしまいます…
          } else {
            content += '<br>【' + num + '】' + arr[i];
          }
          this.show.correctBox = true;
        }
        this.content.correct = content;
        this.show.correctBox = true;
      } //組合せ型はcheckboxに表示されているので、表示は不要
      const comment =
        `（※選択肢はランダムで抽出されるため、表示されなかった選択肢の解説もあります）\n\n` +
        this.contQ_insertClassTagForIframe(ele['comment']); //iframeにクラスタグを挿入
      if (comment != undefined && comment != '') {
        this.content.comment = await this.getHtmlCommentStr(comment);
        this.show.commentBox = true;
      }
    },
    //新データでは削除、旧データでもほぼほぼ使用していないので止めている
    setReference(ele, selKamokuName, publicData) {
      const ref = publicData[selKamokuName].reference;
      if (ref == undefined) {
        //referanceが無い場合は非表示
        this.common.contentRef = '';
        this.common.showRefBlock = false;
        return;
      }
      const refData = this.gf_getArrayFromObj(ref); //FS型のデータを配列に変換する

      const sectionId = ele['sectionId'];
      const ind = refData.findIndex((data) => data['sectionId'] == sectionId);
      if (ind == -1) {
        return;
      }

      const reference = this.contQ_insertClassTagForIframe(refData[ind]['tag']); //iframeにクラスタグを挿入
      this.common.contentRef = reference;
      this.common.showRefBlock = true;
    },
    setExRef(ele) {
      if (!this.gf_isNewType(this.selKamokuName)) {
        this.setExRef_old(ele);
        return;
      }
      const ref = ele.obj_reference;
      const arrExRef = [];
      for (let i in ref) {
        const obj = ref[i];
        if (obj.url == '') {
          continue;
        }
        const data = { ind: i, title: obj.title, url: obj.url }; //レンダリングでの重複回避でindをいれている
        arrExRef.push(data);
      }
      this.arrExRef = arrExRef;
      this.exRef.showExRefBlock = arrExRef.length > 0;
    },
    //旧データ用
    setExRef_old(ele) {
      if (ele.exRef == undefined) {
        return;
      }
      if (ele.exRef == '') {
        return;
      }
      let arr = ele.exRef.split('@');
      arr.shift(); //先頭は空なので削除

      const arrExRef = [];
      for (var i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('$'); //$の前にはタイトルが入っているはずなので、shiftはしない
        const data = { ind: i, title: arr2[0], url: arr2[1] }; //レンダリングでの重複回避でindをいれている
        arrExRef.push(data);
      }
      this.arrExRef = arrExRef;
      this.exRef.showExRefBlock = arrExRef.length > 0;
    },
  },
};
</script>
