<template>
  <div>
    <br />
    <v-row>
      <strong>
        <span v-if="contBtn.numberOfSelect > 0"
          >【{{ contBtn.numberOfSelect }}つ選択】</span
        >
        <span v-else-if="contBtn.numberOfSelect == 0">【すべて選択】</span>
      </strong>
      <span v-if="disBtnShow && disBtnCorrect" class="incorrect-text"
        >不正解</span
      >
      <span v-if="disBtnShow && disBtnIncorrect" class="correct-text"
        >正解</span
      >
      <v-spacer></v-spacer>
      <v-btn height="25px" @click="allUncheck()" v-bind:disabled="disBtn"
        >全件解除</v-btn
      >
    </v-row>
    <br />
    <span style="white-space: pre-wrap; word-wrap: break-word">
      <span v-for="(item, i) in contBtn.checkBoxItems" :key="i">
        <div>
          <p class="indent">
            <input
              type="checkbox"
              class="big"
              v-model="item.checked"
              @change="changed(item)"
              v-bind:disabled="contBtn.disCheckBox"
            />
            &nbsp;
            <label v-bind:style="{ color: item.color }"> {{ item.text }}</label>
          </p>
        </div>
      </span>
    </span>
  </div>
</template>

<style>
p.indent {
  padding-left: 2em;
  text-indent: -1.8em;
  margin: 5px 0px;
}
input.big {
  transform: scale(1.5);
}
.correct-text {
  font-weight: bold;
  color: red;
}
.incorrect-text {
  font-weight: bold;
  color: blue;
}
</style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'CheckBox',
  data() {
    return {};
  },
  computed: {
    ...mapState(['cont', 'contBtn']),
    disBtn() {
      return this.contBtn.btnAllUncheck;
    },
    disBtnIncorrect() {
      return this.contBtn.disBtnIncorrect;
    },
    disBtnCorrect() {
      return this.contBtn.disBtnCorrect;
    },
    disBtnShow() {
      return this.contBtn.disBtnShow;
    },
  },
  methods: {
    //======================================================================================
    changed(item) {
      const ele = this.cont.qOriginal;
      if (item.checked) {
        //チェックされたときの処理
        item.color = 'blue';
        if (ele['queFormat'] == 'perfectMatch') {
          //順番完全一致型ならナンバリングする
          var flag = false;
          for (var i = 0; i < this.contBtn.selCheckBoxItems.length; i++) {
            //選択済み配列を検索して、空白があればそこに格納
            if (this.contBtn.selCheckBoxItems[i] == '') {
              this.contBtn.selCheckBoxItems[i] = item; //※※※直接代入してます・・・
              const num = Number(i) + 1;
              item.text = '【' + num + '】' + item.value;
              item['perfectMatch'] = num; //アイテムにperfectMatchを追加する
              flag = true;
              break;
            }
          }
          if (flag == false) {
            //選択済み配列に空室がなければ、新たに追加
            this.contBtn.selCheckBoxItems.push(item); //※※※直接代入してます・・・
            item.text =
              '【' + this.contBtn.selCheckBoxItems.length + '】' + item.value;
            item['perfectMatch'] = this.contBtn.selCheckBoxItems.length; //アイテムにperfectMatchを追加する
          }
        } else {
          this.contBtn.selCheckBoxItems.push(item); //※※※直接代入してます・・・順番完全一致型でなければ、そのまま解答配列に追加
        }
      } else {
        //チェックが外れたときの処理
        item.color = '';
        const targetInd = this.contBtn.selCheckBoxItems.findIndex(
          (data) => data['randId'] == item.randId,
        );
        if (ele['queFormat'] == 'perfectMatch') {
          //完全一致型の場合はチェックが外れた要素を空白とする
          this.contBtn.selCheckBoxItems[targetInd] = ''; //※※※直接代入してます・・・
          item['perfectMatch'] = '';
          item.text = item.value;
        } else {
          //組合せ型の場合は、チェックが外れた要素を削除する
          this.contBtn.selCheckBoxItems.splice(targetInd, 1); //※※※直接操作してます・・・indの要素を1つ削除して詰める
        }
      }
    },
    allUncheck() {
      for (var i = 0; i < this.contBtn.checkBoxItems.length; i++) {
        //※直でstateに格納してます。ごめんめんどくさい。
        this.contBtn.checkBoxItems[i]['checked'] = false;
        this.contBtn.checkBoxItems[i]['color'] = '';
        this.contBtn.checkBoxItems[i]['text'] =
          this.contBtn.checkBoxItems[i]['value'];
      }
      this.contBtn.selCheckBoxItems = [];
    },
  },
};
</script>
