<template>
  <div>
    <!-- ボタン操作 -->
    <v-footer app inset color="grey">
      <v-row style="display: flex; justify-content: center">
        <v-col cols="3">
          <v-btn
            width="100%"
            color="green lighten-3"
            dark
            v-bind:disabled="disBtnShow"
            @click="scoring()"
          >
            <div v-if="disBtnShow">解答</div>
            <div v-else>解答<v-icon>mdi mdi-gesture-tap</v-icon></div>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            width="90%"
            color="orange lighten-1"
            dark
            v-bind:disabled="!disBtnShow"
            @click="saveAndNext(contBtn.disBtnIncorrect)"
          >
            <div>次へ</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<style></style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ContBtnPractice',
  data() {
    return {};
  },
  computed: {
    ...mapState(['cont', 'contBtn']),
    disBtnShow() {
      return this.contBtn.disBtnShow;
    },
  },
  methods: {
    scoring() {
      this.contBtn.disBtnShow = true;
      this.contBtn.disCheckBox = true;
      const judge = this.contQ_scoringMain();
      if (judge) {
        this.contBtn.disBtnCorrect = false;
      } else {
        this.contBtn.disBtnIncorrect = false;
      }
      //解説を表示させる
      this.$emit('fromContBtn_showComment');
    },
    saveAndNext(isCorrect) {
      this.$emit('fromContBtn_saveAndNext', isCorrect);
    },
    memo() {
      alert('メモ機能は開発中です...');
    },
  },
};
</script>
